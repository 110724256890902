<template>
    <div>
        <v-card outlined>
            <v-card-title>Statistieken</v-card-title>
            <v-card-text>
                <div class="statistieken-row">
                    <div class="statistieken-col">
                        <span class="title ml-2">Per maand</span>
                        <v-data-table
                            :options="tableOptions"
                            :headers="[
                                {
                                    text: 'Maand',
                                    value: 'month',
                                    sortable: true
                                },
                                {
                                    text: 'Aantal rapporten',
                                    value: 'amount',
                                    sortable: true
                                }
                            ]"
                            :items="
                                parsedTaxaties.reduce((acc, taxatie) => {
                                    const datum = moment(taxatie.datum_taxatie, 'DD-MM-YYYY').format('MM-YYYY');
                                    if (acc.some(item => item.month === datum)) {
                                        acc.find(item => item.month === datum).amount += 1;
                                    } else {
                                        if (moment(datum, 'MM-YYYY').isValid()) {
                                            acc.push({ month: datum, amount: 1 });
                                        }
                                    }
                                    return acc;
                                }, [])
                            "
                        ></v-data-table>
                    </div>
                    <div class="statistieken-col">
                        <span class="title ml-2">Per week</span>
                        <v-data-table
                            :options="tableOptions"
                            :headers="[
                                {
                                    text: 'Week',
                                    value: 'week',
                                    sortable: true
                                },
                                {
                                    text: 'Aantal rapporten',
                                    value: 'amount',
                                    sortable: true
                                }
                            ]"
                            :items="
                                parsedTaxaties.reduce((acc, taxatie) => {
                                    const datum = moment(taxatie.rapportnummer, 'DD-MM-YYYY').format('W-YYYY');
                                    if (acc.some(item => item.week === datum)) {
                                        acc.find(item => item.week === datum).amount += 1;
                                    } else {
                                        if (moment(datum, 'W-YYYY').isValid()) {
                                            acc.push({ week: datum, amount: 1 });
                                        }
                                    }
                                    return acc;
                                }, [])
                            "
                        ></v-data-table>
                    </div>
                </div>
            </v-card-text>
        </v-card>
        <v-alert
            name="fade"
            border="top"
            color="secondary"
            class="rounded-0 text-center taxaties-lijst-bottom-alert white--text"
            v-if="loadingText"
        >
            <v-progress-circular :size="20" indeterminate color="primary"></v-progress-circular> {{ loadingText }}
        </v-alert>
    </div>
</template>

<script>
import * as moment from 'moment';
import axios from '../../axios';

export default {
    data() {
        return {
            moment,
            isLoading: false,
            klanten: [],
            parsedTaxaties: [],
            loadingText: '',
            tableOptions: {
                itemsPerPage: 12
            }
        };
    },
    async mounted() {
        await this.getTaxaties();
    },
    methods: {
        async getTaxaties() {
            this.isLoading = true;

            if (localStorage.getItem('taxaties')) {
                this.klanten = JSON.parse(localStorage.getItem('klanten'));
                this.parsedTaxaties = (JSON.parse(localStorage.getItem('taxaties')) || []).map(this.parseTaxatie);
            }

            this.loadingText = 'Klantgegevens ophalen... (stap 1/2)';
            this.klanten = (await axios.get('/users')).data;

            this.loadingText = 'Taxaties ophalen... (stap 2/2)';
            this.parsedTaxaties = (await axios.get('/taxaties/all')).data.map(this.parseTaxatie);

            this.loadingText = '';

            this.isLoading = false;
        },
        parseTaxatie(taxatie) {
            const klant = this.klanten.find(item => item._id === taxatie.klant);

            return {
                ...taxatie,
                klant: klant ? klant.naam : taxatie.klant
            };
        }
    }
};
</script>

<style scoped>
.statistieken-row {
    display: flex; 
    flex-wrap: wrap;
    gap: 32px;
}

.statistieken-col {
    flex: 1;
    padding: 8px;
    margin: 0.5rem 0;
    border-radius: 20px;
    border: 2px #ccc solid;
}

.taxaties-lijst-bottom-alert {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    margin: 0;
}
</style>
