var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Statistieken")]),_c('v-card-text',[_c('div',{staticClass:"statistieken-row"},[_c('div',{staticClass:"statistieken-col"},[_c('span',{staticClass:"title ml-2"},[_vm._v("Per maand")]),_c('v-data-table',{attrs:{"options":_vm.tableOptions,"headers":[
                            {
                                text: 'Maand',
                                value: 'month',
                                sortable: true
                            },
                            {
                                text: 'Aantal rapporten',
                                value: 'amount',
                                sortable: true
                            }
                        ],"items":_vm.parsedTaxaties.reduce(function (acc, taxatie) {
                                var datum = _vm.moment(taxatie.datum_taxatie, 'DD-MM-YYYY').format('MM-YYYY');
                                if (acc.some(function (item) { return item.month === datum; })) {
                                    acc.find(function (item) { return item.month === datum; }).amount += 1;
                                } else {
                                    if (_vm.moment(datum, 'MM-YYYY').isValid()) {
                                        acc.push({ month: datum, amount: 1 });
                                    }
                                }
                                return acc;
                            }, [])}})],1),_c('div',{staticClass:"statistieken-col"},[_c('span',{staticClass:"title ml-2"},[_vm._v("Per week")]),_c('v-data-table',{attrs:{"options":_vm.tableOptions,"headers":[
                            {
                                text: 'Week',
                                value: 'week',
                                sortable: true
                            },
                            {
                                text: 'Aantal rapporten',
                                value: 'amount',
                                sortable: true
                            }
                        ],"items":_vm.parsedTaxaties.reduce(function (acc, taxatie) {
                                var datum = _vm.moment(taxatie.rapportnummer, 'DD-MM-YYYY').format('W-YYYY');
                                if (acc.some(function (item) { return item.week === datum; })) {
                                    acc.find(function (item) { return item.week === datum; }).amount += 1;
                                } else {
                                    if (_vm.moment(datum, 'W-YYYY').isValid()) {
                                        acc.push({ week: datum, amount: 1 });
                                    }
                                }
                                return acc;
                            }, [])}})],1)])])],1),(_vm.loadingText)?_c('v-alert',{staticClass:"rounded-0 text-center taxaties-lijst-bottom-alert white--text",attrs:{"name":"fade","border":"top","color":"secondary"}},[_c('v-progress-circular',{attrs:{"size":20,"indeterminate":"","color":"primary"}}),_vm._v(" "+_vm._s(_vm.loadingText)+" ")],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }